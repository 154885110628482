import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import * as classes from "./downloads.module.css"

export default function Vision({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Corporate- Vision and Policy</title>
      </Helmet>
      <main className={classes.downloads}>
        <h3>Vision</h3>
        <p>
          To be among the top 10 most preferred brands with design-driven models
          in its segment.
        </p>
        <h3>Mission</h3>
        <p>
          To be the leading, competative, environmentally and collectively
          sensitive company in the production and sale of consumer goods.
        </p>
        <h3>Policy</h3>
        <p>
          Quartz is committed to producing products that are of the highest
          quality. It strives to continually improve in all areas and strictly
          ensures that safety and the environment are prioritised. Through
          continual communication, Quartz aims to engage its employees who they
          see as essential in developing the brand.
        </p>
      </main>
    </Layout>
  )
}
